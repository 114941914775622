import React from 'react';
import './App.css';
import Search from './components/Search';
import Navigation from './components/Navegation';
import { IoCartOutline, IoList, IoRadioOutline } from 'react-icons/io5';
import { RiHome2Fill, RiPagesFill } from "react-icons/ri";


function App() {
  const links = [
    { href: '/', label: 'Inicio', icon: <RiHome2Fill size={24}/> },
    { href: 'https://radio.parroquia.co', label: 'Radio', icon: <IoRadioOutline size={24}/> },
    { href: 'https://pages.parroquia.co', label: 'Paginas', icon: <RiPagesFill size={24}/> },
    { href: 'https://forms.parroquia.co', label: 'Formularios', icon: <IoList size={24}/> },
    { href: 'https://tienda.parroquia.co', label: 'Tienda', icon: <IoCartOutline size={24}/> },
  ];

  return (
    <div className="min-h-screen flex justify-center items-center">
      <div className="w-full">
        <Navigation links={links} />
        <Search />
      </div>
    </div>
  );
}

export default App;
