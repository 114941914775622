// Este código se toma de CRA's service worker template y se ha adaptado ligeramente
const isLocalhost = Boolean(
    window.location.hostname === 'localhost' ||
      // [::1] es el localhost de IPv6.
      window.location.hostname === '[::1]' ||
      // 127.0.0.0/8 son considerados localhost para IPv4.
      window.location.hostname.match(
        /^127(?:\.[0-9]+){0,2}\.[0-9]+$/
      )
  );
  
  export function register(config) {
    if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
      // La URL del archivo service-worker.js
      const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
      if (publicUrl.origin !== window.location.origin) {
        // Nuestro service worker no funcionará si PUBLIC_URL está en un origen diferente.
        return;
      }
  
      window.addEventListener('load', () => {
        const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;
  
        if (isLocalhost) {
          // Esto es ejecutado para localhost. Comprobamos si el service worker aún existe o no.
          checkValidServiceWorker(swUrl, config);
  
          // Añadimos una lógica adicional para recargar la página si estamos en localhost.
          navigator.serviceWorker.ready.then(() => {
            console.log(
              'Esta aplicación está siendo servida en caché por un service worker.'
            );
          });
        } else {
          // Registramos el service worker en producción.
          registerValidSW(swUrl, config);
        }
      });
    }
  }
  
  function registerValidSW(swUrl, config) {
    navigator.serviceWorker
      .register(swUrl)
      .then(registration => {
        registration.onupdatefound = () => {
          const installingWorker = registration.installing;
          if (installingWorker == null) {
            return;
          }
          installingWorker.onstatechange = () => {
            if (installingWorker.state === 'installed') {
              if (navigator.serviceWorker.controller) {
                // Ejecuta este bloque si hay contenido nuevo disponible.
                console.log(
                  'Nuevo contenido disponible; por favor, recarga la página.'
                );
  
                // Ejecuta el callback de actualización.
                if (config && config.onUpdate) {
                  config.onUpdate(registration);
                }
              } else {
                // Ejecuta este bloque si no hay contenido previo en caché.
                console.log('Contenido en caché para uso sin conexión.');
  
                // Ejecuta el callback de éxito.
                if (config && config.onSuccess) {
                  config.onSuccess(registration);
                }
              }
            }
          };
        };
      })
      .catch(error => {
        console.error('Error durante el registro del service worker:', error);
      });
  }
  
  function checkValidServiceWorker(swUrl, config) {
    // Comprobamos si el service worker puede ser encontrado.
    fetch(swUrl, {
      headers: { 'Service-Worker': 'script' },
    })
      .then(response => {
        // Comprueba si el service worker existe o no.
        const contentType = response.headers.get('content-type');
        if (
          response.status === 404 ||
          (contentType != null && contentType.indexOf('javascript') === -1)
        ) {
          // Si el service worker no se encuentra, recargamos la página.
          navigator.serviceWorker.ready.then(registration => {
            registration.unregister().then(() => {
              window.location.reload();
            });
          });
        } else {
          // Si el service worker se encuentra, lo registramos.
          registerValidSW(swUrl, config);
        }
      })
      .catch(() => {
        console.log(
          'No se encontró conexión a Internet. La aplicación está funcionando en modo offline.'
        );
      });
  }
  
  export function unregister() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.ready
        .then(registration => {
          registration.unregister();
        })
        .catch(error => {
          console.error(error.message);
        });
    }
  }
  