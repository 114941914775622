import React, { useState } from 'react';
import { IoIosClose, IoIosMenu } from 'react-icons/io';

const Navigation = ({ links }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const handleLinkClick = () => {
    setMenuOpen(false);
  };

  const NavigationLink = ({ href, label, icon }) => (
    <a href={href} className="block py-2 px-2 rounded-md lg:inline-block lg:mt-0 bg-gray-900 hover:bg-slate-700 text-white border-t md:border-none border-gray-600" onClick={handleLinkClick}>
      <div className='flex flex-row items-center justify-between space-x-2'>
        <div>{icon}</div>
        <div>{label}</div>
      </div>

    </a>
  );

  return (
    <nav className="flex items-center justify-between flex-wrap bg-gray-800 py-3 px-6">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <a href="#inicio" className="font-semibold text-xl tracking-tight">Parroquia.co</a>
      </div>
      <div className="block lg:hidden">
        <button className="flex items-center px-3 py-2 border rounded text-white border-white focus:outline-none" onClick={handleMenuToggle}>
          {menuOpen ? <IoIosClose size={25} /> : <IoIosMenu size={25} />}
        </button>
      </div>
      <div className={`w-full block flex-grow lg:flex lg:items-center lg:w-auto ${menuOpen ? 'block' : 'hidden'}`}>
        <div className="text-sm lg:flex-grow lg:space-x-4">
          {links.map((link, index) => (
            <NavigationLink key={index} href={link.href} label={link.label} icon={link.icon} />
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
