import React, { useEffect } from 'react';

const Search = () => {
  const currentYear = new Date().getFullYear();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://cse.google.com/cse.js?cx=ce1e503fb074dd061';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="relative min-h-screen flex justify-center items-center pt-12">
      <div className="absolute inset-0 z-0 bg-cover bg-center" style={{ backgroundImage: 'url(https://source.unsplash.com/1600x900/?church)' }}></div>
      <div className="relative z-10 max-w-4xl w-full px-4">
        <div className="bg-white bg-opacity-60 p-2 rounded-lg shadow-lg">
          <div className="flex justify-center py-4 -mt-16">
            <img src="/logo-h.png" alt="Buscador Parroquia.co" className="w-40 md:w-60 rounded-lg shadow-lg" />
          </div>
          <div className="bg-white rounded-t-lg py-2 shadow-inner">
            <div className="gcse-search"></div>
          </div>
          <div className='text-center text-xs pt-2 bg-gray-800 rounded-b-lg py-2 text-gray-300'>
            <p>{currentYear} Parroquia.co | Veritatem communicare et annuntiare</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
